.hide {
    display: none !important;
}

@-webkit-keyframes spin-vertical-3d {
    from {
        transform: none;
    }
    50% {
        transform: rotateY(180deg);
    }
    to {
        transform: rotateY(359deg);
    }
}

.stretch-width {
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
}

.stretch-height {
    height: -webkit-fill-available;
    height: -moz-available;
    height: stretch;
}

@master-nav-height: 40px;
@master-nav-home-height: 50px;

@top-bar-drawer-width: 500px;
@top-bar-drawer-width-min: 360px;

/* Elevations */
@dku-shadow-elevation-0: 0px 5px 6px rgba(0, 0, 0, 0.1);
@dku-shadow-elevation-1: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
@dku-shadow-elevation-2: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.2), 0px 1px 5px rgba(0, 0, 0, 0.12);
@dku-shadow-elevation-3: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
@dku-shadow-elevation-4: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.2), 0px 1px 8px rgba(0, 0, 0, 0.12);
@dku-shadow-elevation-5: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
@dku-shadow-elevation-6: 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);
@dku-shadow-elevation-7: 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);

@dku-shadow-chip:           @dku-shadow-elevation-1;
@dku-shadow-top-bar:        @dku-shadow-elevation-2;
@dku-shadow-page:           @dku-shadow-elevation-3;
@dku-shadow-tile:           @dku-shadow-elevation-3;
@dku-shadow-sidebar:        @dku-shadow-elevation-4;
@dku-shadow-menu:           @dku-shadow-elevation-5;
@dku-shadow-notification:   @dku-shadow-elevation-6;
@dku-shadow-tile-hover:     @dku-shadow-elevation-6;
@dku-shadow-modal:          @dku-shadow-elevation-7;
