.mx-ssp(@size, @weight) {
    font-family: 'SourceSansPro';
    font-weight: @weight;
    font-size: @size;
}

.mx-typography(@font-weight, @font-size, @line-height) {
    font-weight: @font-weight;
    font-size: @font-size;
    line-height: @line-height;
    color: @text-important;
}

.mx-huge-title(@font-weight) {
    .mx-typography(@font-weight, 38px, 48px);
}

.mx-ultralarge-title(@font-weight) {
    .mx-typography(@font-weight, 32px, 40px);
}

.mx-extralarge-title(@font-weight) {
    .mx-typography(@font-weight, 30px, 38px);
}

.mx-large-title(@font-weight) {
    .mx-typography(@font-weight, 26px, 33px);
}

.mx-grand-title(@font-weight) {
    .mx-typography(@font-weight, 22px, 28px);
}

.mx-medium-title(@font-weight) {
    .mx-typography(@font-weight, 18px, 23px);
}

.mx-small-title(@font-weight) {
    .mx-typography(@font-weight, 16px, 20px);
}

.mx-small-sub-title(@font-weight) {
    .mx-typography(@font-weight, 14px, 18px);
}

.mx-text(@font-weight) {
    .mx-typography(@font-weight, 13px, 16px);
}

.mx-tiny-text(@font-weight) {
    .mx-typography(@font-weight, 10px, 13px);
}
