@import "../sharedcss/colors";
@import "./typography.less";

.mat-option {
    &.mat-active {
        background: fade(@grey-base, 4%);
        color: fade(@grey-base, 97%);
    }
}

.mat-autocomplete-panel {
    background: @white-background;
    color: fade(@grey-base, 97%);
    min-width: 112px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    max-width: none;
    max-height: 256px;
    position: relative;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.mx-capped-height {
    max-height: calc(~"100vh - 60px");
}

.dku-modal-panel-full, .dku-modal-panel-wide, .dku-modal-panel-narrow, .dku-modal-panel-none {
    .mat-dialog-container {
        box-shadow: 4px 4px 10px fade(@grey-base, 15%);
        padding: 0;
        border-radius: 0;
        > * > *, .modal-root { // root element of the component template usually a div
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
        }
        modal-header {
            flex: 0 0 auto;
        }
        .modal-body {
            position: relative;
            flex: 1 1 auto;
            max-height: none;
        }
    }
}

.dku-modal-panel-full {
    .mat-dialog-container {
        width: 100vw;
        .mx-capped-height;
    }
}

.dku-modal-panel-wide .mat-dialog-container {
    width: 900px;
    .mx-capped-height;
}

.dku-modal-panel-narrow .mat-dialog-container {
    width: 560px;
    .mx-capped-height;
}

.dku-modal-panel-wide, .dku-modal-panel-full, .dku-modal-panel-narrow, .dku-modal-panel-none {
    margin: auto;

    .modal-header {
        border-bottom: none;
        padding: 0;
        height: 70px;
        position: relative;
        background-color: @white-background;
        &.has-border {
            border-bottom: 1px solid @border-color;
        }
        &.no-totem .modal-title {
            padding-left: 20px;
        }
        .modal-totem {
            position: absolute;
            left: 20px;
            top: 25px;
            width: 18px;
            height: 28px;
            text-align: center;
            vertical-align: middle;
            &.modal-totem--action {
                cursor: pointer;
                padding: 0 6px 0 0;
            }
            i {
                line-height: 28px;
                font-size: 18px;
                text-align: center;
                display: inline;
                opacity: 0.60;
                &:before:not([class*="dku-icon-"]) {
                    vertical-align: middle;
                }
            }
        }
        .modal-title {
            margin: 0;
            padding: 25px 0 0 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
            .mx-ssp(22px, lighter);
            line-height: initial;
            color: @grey-lighten-1;
            &.has-icon {
                padding-left: 55px;
            }
        }
        .modal-header-button {
            float: right;
            margin-top: 30px;
            margin-right: 20px;
            padding: 0;
            border: 0;
            cursor: pointer;
            outline: none;
            font-size: 20px;
            font-weight: bold;
            line-height: 10px;
            height: 15px;
            color: @grey-lighten-5;
            text-decoration: none;

            &:hover {
                color: @grey-lighten-3;
                text-decoration: none;
            }
        }
    }
    .modal-header-only {
        height: auto;
        min-height: 70px;
        margin-bottom: 20px;
        h4 {
            overflow: auto;
            text-overflow: inherit;
            white-space: inherit;
            strong {
                font-weight: normal;
            }
        }
    }

    .modal-body {
        padding: 16px;
        &.tight-body {
            padding-bottom: 0;
        }
        &.no-padding {
            padding: 0;
        }
        &.no-h-padding {
            padding-left: 0;
            padding-right: 0;
        }
        &.no-v-padding {
            padding-bottom: 0;
            padding-top: 0;
        }

        .modal-h-padding {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .modal-body--tabs {
        padding: 0;
        overflow: hidden;

        .mat-tab-body-wrapper {
            padding: 0 24px;
            height: 450px;
        }

        .mat-tab-body-content {
            overflow: hidden;
        }
    }

    .fixed-height500 {
        height: 500px;
        max-height: 500px;
        box-sizing: border-box;
    }

    .fixed-height250 {
        height: 250px;
        max-height: 250px;
        box-sizing: border-box;
    }

    .modal-footer {
        background-color: @white-background;
        height: 50px;
        padding: 7px 10px 8px 0;
        line-height: 34px; // 50px - padding - border (if there is no border, you won't notice the 1px discrepancy)
        box-sizing: border-box;
        border-top: none;
        &.has-border {
            border-top: 1px solid @border-color;
        }
    }
}

.modal-footer-std-buttons {
    font-size: 0;
    .btn {
        font-size: 15px;
        padding: 2px 10px;
        margin-left: 10px;
    }
    .btn-default {
        color: @text-weak;
        background-color: @white-background;
        &:hover {
            color: @white-base;
            background-color: @grey-lighten-3;
        }
        &:active {
            background: @grey-lighten-1;
            color: @white-base;
        }
    }
    .btn-primary {
        color: @digital-blue-base;
        background: @white-base;

        &:focus {
            color: @digital-blue-base;
            background: @white-base;
        }
        &:hover {
            color: @white-base;
            background: @btn-primary-background-hover;
        }
        &:active {
            background: @btn-primary-background-active;
            color: @white-base;
        }
        &:disabled {
            background: @white-base;
            color: @digital-blue-lighten-3;
        }
        &.light-style {
            border: 1px @btn-primary-border solid;

            &:focus {
                color: @digital-blue-base;
                background: @white-base;
            }
            &:hover {
                color: @white-base;
                background: @btn-primary-background-hover;
            }
            &:active {
                background: @btn-primary-background-active;
                color: @white-base;
            }
            &:disabled {
                background: @white-base;
                color: @btn-success-text-disabled;
            }
        }
    }
    .btn-danger {
        background: @white-base;
        color: @error-red-base;
        &:hover {
            color: @white-base;
            background: @btn-danger-background-hover;
        }
        &:active, &:focus {
            background: @btn-danger-background-active;
            color: @white-base;
        }
        &:disabled {
            background: @white-base;
            color: @btn-danger-text-disabled;
            opacity: 0.3;
        }
    }
    .btn-teal {
        background: @white-base;
        color: @teal-base;
        &:hover {
            color: @white-base;
            background: fade(@teal-base, 60%);
        }
        &:active, &:focus {
            color: @white-base;
            background: fade(@teal-base, 90%);
        }
    }
}

.cdk-overlay-container.see-through {
    ul.modal-tabs, div.project-tiles {
        transition: visibility 0s;
        // transition-delay: 0.25s;
        visibility: hidden;
    }
    .mat-dialog-container, .modal-header-button, .modal {
        transition: background-color 0.5s;
        transition-delay: 0.25s;
        background-color: transparent;
    }
    div.modal-body, div.modal-footer {
        transition: visibility 0s;
        transition-delay: 0.25s;
        visibility: hidden;
    }
    div.modal-header {
        transition: opacity 0.5s;
        // transition-delay: 0.25s;
        opacity: 0.5;
    }
    .see-through-button {
        color: @grey-base;
        opacity: 1;
    }
}

div.popover.see-through {
    transition: visibility 0s;
    transition-delay: 0.25s;
    visibility: hidden;
}

/**
 * The IO modal is split in two halves with a "Input -> Output" header
 */
.io-modal {
    // the font for text in the modal
    font-weight: normal;
    letter-spacing: 0px;

    .modal-body {
        padding: 0px;
        .half-pane.half-pane {
            height: 100%;
            width: 50%;
            box-sizing: border-box;
            float: left;
            &:first-child {
                border-right: 1px solid @border-color;
                padding-right: 0px;
            }
            &:last-child {
                padding-left: 0px;
            }
            h4 {
                .mx-ssp(19px, lighter);
                margin: 0px;
                i:after {
                    content: "   ";
                }
            }
            .header-text {
                border-bottom: 1px solid @border-color;
                line-height: 45px;
                color: @grey-lighten-3;
                text-align: center;
            }
        }
    }
}
