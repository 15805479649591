@import (reference) "~src/static/sharedcss/color-variables";
@import (reference) "~src/static/sharedcss/layout-variables.less";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "ag-grid-community/styles/ag-theme-balham.css";

.ag-theme-balham {
    font-family: 'SourceSansPro';
}

.ag-theme-balham .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 12px;
}

.ag-cell-wrapper.ag-row-group {
    align-items: center;
}

.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    padding-left: 8px;
    padding-right: 8px;
}

.ag-theme-balham .ag-cell {
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 4px;
    border-width: 0;
}

.ag-theme-balham .ag-ltr .ag-cell {
    border-right-width: 1px;
}

.ag-theme-alpine {
    --ag-card-radius: 0;
    --ag-card-shadow: 0;
    --ag-popup-shadow: var(--ag-card-shadow);
    --ag-font-size: 12px;
}


.ag-grid-row-index-cell {
    background-color: @grey-lighten-8;
    border-right-color: @grey-lighten-5;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    font-size: 10px;
    padding-left: @spacing-half;
    padding-right: @spacing-half;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ag-cell-range-selected-1 {
        &.ag-cell-range-selected {
            background-color: @grey-lighten-5;
            border-color: transparent;
        }

        &.ag-cell-range-selected:not(.ag-cell-range-single-cell) {
            &.ag-cell-range-left {
                border-left-color: transparent;
            }

            &.ag-cell-range-right {
                border-right-color: transparent;
            }

            &.ag-cell-range-top {
                border-top-color: transparent;
            }

            &.ag-cell-range-bottom {
                border-bottom-color: transparent;
            }
        }
    }
}

.ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-grid-row-index-cell.ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: @grey-lighten-8;
    border-right-color: @grey-lighten-5;
}

.ag-status-bar {
    height: 45px;
}

.ag-cell-normal-height.ag-cell-inline-editing {
    // Show overflow text editor for non autoheight cells
    overflow: visible;
}

.ag-header-active {
    .spreadsheet-column-header__menu-toggle {
        &:hover {
            color: var(--ag-alpine-active-color);
        }
        visibility: visible;
    }
}
