@import "~src/static/sharedcss/color-variables";

// We use the ant design theme of <ng-select> as a starting point before applying DSS customizations

.ng-dropdown-panel {
    display: block !important;
    z-index: 4001;
    width: auto !important; // Auto-resize menu width (as long as virtual scroll is disabled)
    max-width: 400px;
    border-radius: 0;
    margin-top: 0px;
    box-shadow: 2px 2px 8px 1px rgb(34 34 34 / 20%);

    .ng-dropdown-header {
        border-bottom: none;
        padding: 0;
    }

    .ng-dropdown-panel-items {
        .ng-option {
            display: flex !important;
            font-weight: inherit !important;
            transition: none !important;
            color: @grey-lighten-1  !important;
            border-radius: none;

            &.ng-option-selected,
            &.ng-option-marked {
                background: @grey-background !important;
            }

            &.ng-option-disabled {
                opacity: 0.5;
            }
        }

        .ng-optgroup {
            font-size: 15px;
            color: @grey-lighten-1  !important;
            font-weight: bold;
        }

        .ng-option,
        .ng-optgroup {
            padding: 2px 6px !important;
        }
    }
}

// Note: <ng-select> propagates its "class" attribute to the <ng-dropdown-panel> because it may not be a child
// (e.g. if dropdown is appended to body)
// Example usage: <ng-select class="ng-select--with-ng-option-separator">
.ng-select,
.ng-dropdown-panel {

    // Add a line separator between options
    &.ng-select--with-ng-option-separator {
        .ng-dropdown-panel-items {

            .ng-option,
            .ng-optgroup {
                &:not(:first-of-type) {
                    border-top: 1px @light-border-color solid;
                    margin-top: -1px;
                }
            }
        }
    }

    // Remove default padding around each option/group and let the consumer deal with it
    &.ng-select--without-ng-option-padding {
        .ng-dropdown-panel-items {
            .ng-option,
            .ng-optgroup {
                padding: 0 !important;
            }
        }
    }

    // Do not reduce opacity of disabled items and let the consumer deal with it
    &.ng-select--without-ng-option-disabled-opacity {
        .ng-dropdown-panel-items {
            .ng-option.ng-option-disabled {
                opacity: 1;
            }
        }
    }

    &.ng-select--without-highlighting-of-selected-items-if-mutiple.ng-select-multiple {
        .ng-dropdown-panel-items {
            .ng-option {
                &.ng-option-selected:not(.ng-option-marked) {
                    background: @white-base !important;
                }
            }
        }
    }
}


.ng-select {
    // Properties to match existing DSS menus
    width: var(--dku-ng-select-width, 205px); // Allows any component using ng-select to override its width by setting this variable to a custom value.
    max-width: var(--dku-ng-select-max-width, 205px); // Allows any component using ng-select to override its max width by setting this variable to a custom value.
    display: inline-block;
    vertical-align: middle;

    // Usage: <ng-select class="ng-select--no-wrap-selected-items">
    //
    // Disable the "wrapping behavior" when:
    // - Multiple elements are selected
    // - Individual selected items are displayed (default behavior of <ng-select>, works a bit like tags)
    //
    // When the select trigger does not display the selected items as "tags" but a summary text (such as "4 items selected"), we don't
    // want to keep this wrapping behavior because it creates visual glitches.
    &.ng-select--no-wrap-selected-items {
        &.ng-select-multiple .ng-select-container .ng-value-container {
            flex-wrap: nowrap;
            width: 0;
            white-space: nowrap;
            overflow: visible;
        }
    }

    // Below are customizations of the select trigger to make it look like other DSS menus
    &.ng-select-opened>.ng-select-container {
        box-shadow: none;

        .ng-value-container .ng-input>input {
            // Show the <input> when the menu is opened
            opacity: 1;
        }
    }

    &.ng-select-single.ng-select-opened .ng-select-container .ng-value {
        opacity: 1;
    }

    .ng-select-container {
        border-radius: 0;
        border: 1px solid @border-color;
        padding-right: 0px;
        padding-left: 4px;
        min-height: 24px !important;
        transition: none;

        .ng-value-container {
            color: @grey-lighten-1;
            font-size: 13px;
            padding: 0 !important;

            .ng-placeholder {
                margin-left: 0 !important;
                color: @grey-lighten-1;
                font-size: 13px;
            }

            .ng-input {
                padding-right: 4px !important;
                padding-left: 4px !important;

                &,
                &>input {
                    height: 20px !important;
                    vertical-align: top;
                    opacity: 0; // Hide the <input> completely unless the menu is opened (to avoid showing the "blinking bar")
                    border: none !important; // Prevent interference with dkuform styling
                }
            }

            .ng-value {
                color: @grey-lighten-1;
                font-size: 13px;
            }
        }
    }

    .ng-clear-wrapper {
        background: @white-background !important; // Avoid ugly overlap with selected item
        right: 0px;
        width: 24px;
        height: 24px;
        border-radius: 0;

        .ng-clear {
            color: @border-color;
            font-size: 18px;
            cursor: pointer;
            line-height: 24px;
            position: relative;
            left: 0;
        }
    }

    .ng-arrow-wrapper {
        width: 24px;
        height: 24px;
        background: @white-background; // Avoid ugly overlap with selected item
        transition: none;
        transform-origin: 13px 12px;

        .ng-arrow {
            height: 8px;
            width: 8px;
            top: 5px;
            border: none;
            transform: scaleY(.5);
            color: black;
            font-size: 10px;

            &::before {
                content: '▼';
            }
        }
    }

    &.ng-select-disabled .ng-select-container {

        .ng-arrow-wrapper,
        .ng-clear-wrapper {
            background-color: #f5f5f5 !important; // Must matches ng-select's background color when disabled
        }
    }

    &:not(.ng-select-disabled) {
        .ng-select-container {
            cursor: pointer;
        }
    }
}

// Workaround to this issue: https://github.com/ng-select/ng-select/issues/1871
// We need this to allow the 'view' links to be clickable in the label template
// This also breaks the native search bar of ng-select, you cannot click on the
// search bar to change the cursor position. But that's okay since we are not
// using it.
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    pointer-events: none;
}